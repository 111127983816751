.row {
  max-width: 150rem;
  margin: 0 auto; }
  .row:not(:last-child) {
    margin-bottom: 8rem; }
    @media screen and (max-width: 1050px) {
      .row:not(:last-child) {
        margin-bottom: 6rem; } }
  @media screen and (max-width: 1050px) {
    .row {
      max-width: 50rem;
      padding: 0 3rem; } }
  .row::after {
    content: "";
    display: table;
    clear: both; }
  .row [class^="col-"] {
    float: left; }
    .row [class^="col-"]:not(:last-child) {
      margin-right: 8rem; }
      @media screen and (max-width: 1050px) {
        .row [class^="col-"]:not(:last-child) {
          margin-right: 0;
          margin-bottom: 6rem; } }
    @media screen and (max-width: 1050px) {
      .row [class^="col-"] {
        width: 100% !important; } }
  .row .col-1-of-2 {
    width: calc((100% - 8rem) / 2); }
  .row .col-1-of-3 {
    width: calc((100% - 2 * 8rem) / 3); }
  .row .col-2-of-3 {
    width: calc( 2 * ((100% - 2 * 8rem) / 3) + 8rem); }
  .row .col-1-of-4 {
    width: calc((100% - 3 * 8rem) / 4); }
  .row .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * 8rem) / 4) + 8rem); }
  .row .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * 8rem) / 4) + 2 * 8rem); }

*,
*::after,
*::before {
  box-sizing: inherit; }

html {
  font-size: 62.5%; }

body {
  box-sizing: border-box; }

html, body {
  margin: 0;
  padding: 0; }

@media screen and (max-width: 420px) {
  html {
    font-size: 52.5%; } }

@media screen and (max-width: 380px) {
  html {
    font-size: 42.5%; } }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.margin-bottom-sm {
  margin-bottom: 3rem !important; }

.margin-bottom-lg {
  margin-bottom: 8rem !important; }

nav {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  font-family: "Prompt", sans-serif;
  height: 6.5vh;
  min-height: 50px;
  background: white;
  z-index: 70; }
  nav .nav-content-wrapper {
    width: 100%;
    height: 100%;
    position: relative; }
    nav .nav-content-wrapper .listWrapper {
      display: flex;
      height: 100%; }
    nav .nav-content-wrapper .nav-brand {
      display: flex;
      align-items: center;
      width: 200px;
      position: absolute;
      font-size: 2rem;
      z-index: 2;
      left: 5%;
      top: 50%;
      transform: translate(5%, -50%); }
      nav .nav-content-wrapper .nav-brand img {
        width: 100%;
        outline: none; }
    nav .nav-content-wrapper .nav-flags {
      cursor: pointer;
      margin: 0;
      list-style: none;
      height: 100%;
      width: 8%;
      display: flex;
      margin-right: 20px;
      justify-content: space-around;
      align-items: center; }
    nav .nav-content-wrapper .nav-links {
      margin: 0;
      margin-left: auto;
      display: flex;
      list-style: none;
      padding-left: 20px;
      min-width: 360px;
      width: 20%;
      height: 100%;
      justify-content: space-evenly;
      align-items: center; }
      nav .nav-content-wrapper .nav-links li a {
        outline: none;
        color: black;
        font-size: 1.7rem;
        text-decoration: none; }
        nav .nav-content-wrapper .nav-links li a:hover {
          font-size: 2rem; }

.home {
  height: 100vh;
  padding-top: 6.5vh;
  display: flex; }
  .home--slider {
    width: 70vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(/assets/homeSlider/1.jpeg);
    transition: background 0.5s linear;
    -moz-transition: background 0.5s linear;
    animation: homeSlider 64s infinite;
    position: relative;
    z-index: -2;
    background-position: center; }
    .home--slider__desc {
      color: white;
      width: 35%;
      font-size: 2.3rem;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .home--slider__clip {
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: white;
      clip-path: ellipse(350px 800px at -10% 53%); }
  .home--headerContainer {
    height: 100%;
    width: 40%;
    display: flex;
    align-items: center;
    overflow: visible;
    transform: translateX(50px);
    z-index: 6; }
  .home--header {
    height: fit-content;
    padding-top: 20px;
    max-width: 560px;
    margin-left: 20px;
    padding-left: 25px;
    transform: translateX(50px);
    border-left: 5px solid black; }
    .home--header p, .home--header__text {
      font-family: "Manrope", sans-serif; }
    .home--header__text {
      margin: 0;
      font-size: 3.5rem; }
    .home--header p {
      margin-top: 20px;
      letter-spacing: 1.5px;
      font-size: 2rem;
      margin-bottom: 20px; }

.about {
  min-height: 93.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8.5vh; }
  .about-content-wrapper {
    padding: 0 5rem 0 2rem;
    transform: translateY(-8vh); }
    @media screen and (max-width: 1050px) {
      .about-content-wrapper {
        transform: translateY(-6vh); } }
    @media screen and (max-width: 350px) {
      .about-content-wrapper {
        transform: translateY(0vh); } }
  .about .main-heading {
    padding-top: 20px;
    font-size: 10rem; }
    @media screen and (max-width: 1050px) {
      .about .main-heading {
        text-align: center; } }
    @media screen and (max-width: 740px) {
      .about .main-heading {
        font-size: 6rem; } }
  .about .paragraph-header {
    font-size: 3.2rem;
    padding-bottom: 15px; }
    @media screen and (max-width: 350px) {
      .about .paragraph-header {
        font-size: 2.5rem; } }
  .about .paragraph {
    font-size: 2.2rem; }
    @media screen and (max-width: 350px) {
      .about .paragraph {
        font-size: 1.5rem; } }
  .about .composition {
    position: relative;
    min-height: 10px; }
    .about .composition__pics {
      width: 55%;
      box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
      border-radius: 2px;
      position: absolute;
      z-index: 4;
      transition: all 0.2s; }
      @media screen and (max-width: 1050px) {
        .about .composition__pics {
          float: left;
          position: relative;
          width: 33.33333333%;
          box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.2); } }
      .about .composition__pics--pic1 {
        left: 0;
        top: -2rem; }
        @media screen and (max-width: 1050px) {
          .about .composition__pics--pic1 {
            transform: scale(1.2); } }
      .about .composition__pics--pic2 {
        right: 5%;
        top: 2rem; }
        @media screen and (max-width: 1050px) {
          .about .composition__pics--pic2 {
            top: 1.5rem;
            right: 0;
            transform: scale(1.3);
            z-index: 6; } }
      .about .composition__pics--pic3 {
        left: 20%;
        top: 10rem;
        z-index: 5; }
        @media screen and (max-width: 1050px) {
          .about .composition__pics--pic3 {
            top: -2.5rem;
            left: 0;
            transform: scale(1.1); } }
      .about .composition__pics--pic4 {
        left: 5%;
        top: 20rem; }
        @media screen and (max-width: 1050px) {
          .about .composition__pics--pic4 {
            top: -2rem;
            left: 4rem;
            transform: scale(1.1); } }
      .about .composition__pics--pic5 {
        right: 0;
        top: 23rem; }
        @media screen and (max-width: 1050px) {
          .about .composition__pics--pic5 {
            top: -2rem;
            left: 10rem;
            transform: scale(1.1); } }
      .about .composition__pics:hover {
        transform: scale(1.05) translateY(-0.5rem);
        box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
        z-index: 20; }
        @media screen and (max-width: 1050px) {
          .about .composition__pics:hover {
            transform: scale(1.4) translateY(-0.5rem); } }
    .about .composition:hover .about .composition__pics:not(:hover) {
      transform: scale(0.55); }

#products {
  min-height: 93.5vh;
  padding-bottom: 30px;
  padding-top: 8.5vh;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover; }
  @media screen and (max-width: 1400px) {
    #products {
      background-position: bottom; } }
  #products h1 {
    font-size: 9rem;
    text-align: center;
    margin-top: 3%;
    margin-bottom: 20px; }
    @media screen and (max-width: 740px) {
      #products h1 {
        font-size: 6rem; } }
  #products .select-wrapper {
    display: flex;
    justify-content: center; }
    #products .select-wrapper select {
      font-size: 1.5rem;
      padding: 1rem 2rem;
      margin-bottom: 3rem; }
  #products p {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 0; }
    @media screen and (max-width: 740px) {
      #products p {
        font-size: 1.5rem; } }
  #products .slider {
    width: 70%;
    margin: 20px auto; }
  #products .slick-prev {
    transform: scale(1.5) translateX(-10px); }
  #products .slick-next {
    transform: scale(1.5) translateX(10px); }
  #products .slick-prev,
  #products .slick-next {
    background-color: green;
    border-radius: 50%; }
  #products .submenu {
    margin: 0;
    padding: 0;
    list-style: none; }
  #products .flex-center {
    display: flex;
    justify-content: center; }
    #products .flex-center .wrapper {
      float: left;
      z-index: 8;
      background-color: #385a38;
      position: relative;
      transform: skewX(25deg);
      margin-top: 30px;
      margin-bottom: 30px; }
  #products button {
    display: block;
    background: none;
    border: none;
    text-transform: uppercase;
    text-decoration: none;
    font-family: Arial, Helvetica;
    font-size: 14px; }
  #products .wrapper:hover {
    background: #4da571; }
  #products .wrapper button {
    transform: skewX(-25deg);
    padding: 1em 2em;
    color: #fff; }
  #products .submenu {
    position: absolute;
    width: 200px;
    left: 50%;
    margin-left: -100px;
    transform: skewX(-25deg);
    transform-origin: left top;
    /* Odd index elems */
    /* Show dropdown */ }
    #products .submenu li {
      background-color: #34495e;
      background-color: #385a38;
      position: relative;
      overflow: hidden;
      cursor: pointer; }
    #products .submenu > li > button {
      cursor: pointer;
      padding: 1em 2em;
      outline: none; }
    #products .submenu > li::after {
      content: "";
      position: absolute;
      top: -125%;
      height: 100%;
      width: 100%;
      box-shadow: 0 0 50px rgba(0, 0, 0, 0.9); }
    #products .submenu > li:nth-child(odd) {
      transform: skewX(-25deg) translateX(0); }
    #products .submenu > li:nth-child(odd) > button {
      transform: skewX(25deg); }
    #products .submenu > li:nth-child(odd)::after {
      right: -50%;
      transform: skewX(-25deg) rotate(3deg); }
    #products .submenu > li:nth-child(even) {
      transform: skewX(25deg) translateX(0); }
    #products .submenu > li:nth-child(even) > a {
      transform: skewX(-25deg); }
    #products .submenu > li:nth-child(even)::after {
      left: -50%;
      transform: skewX(25deg) rotate(3deg); }
    #products .submenu,
    #products .submenu li {
      opacity: 0;
      visibility: hidden; }
    #products .submenu li {
      transition: 0.2s ease transform; }
  #products .wrapper:hover .submenu,
  #products .wrapper:hover .submenu li {
    opacity: 1;
    visibility: visible; }
  #products .wrapper:hover .submenu li:nth-child(even) {
    transform: skewX(25deg) translateX(15px); }
  #products .wrapper:hover .submenu li:nth-child(odd) {
    transform: skewX(-25deg) translateX(-15px); }
  #products h2 {
    font-size: 5rem;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 30px; }
    @media screen and (max-width: 740px) {
      #products h2 {
        font-size: 4rem; } }
  #products .months {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    #products .months .month {
      font-size: 2rem;
      width: 80px;
      text-align: center;
      transform: skewX(-25deg);
      padding: 10px 20px;
      margin-bottom: 10px; }
      #products .months .month:not(:last-child) {
        margin-right: 10px; }
      #products .months .month .straight {
        display: block;
        transform: skewX(25deg); }
    #products .months .available {
      background-color: #0a794c;
      color: white; }
    #products .months .notAvailable {
      background-color: white;
      border: 1px solid black; }

#contact {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.5)), url("/assets/contact/contact.jpg");
  background-size: cover; }
  #contact .contact-content-wrapper {
    min-height: 93.5vh;
    margin-top: 6.5vh;
    padding-top: 6.5vh;
    color: white; }
    #contact .contact-content-wrapper h1,
    #contact .contact-content-wrapper p {
      text-align: center; }
    #contact .contact-content-wrapper h1 {
      font-size: 10rem;
      margin-bottom: 0; }
      @media screen and (max-width: 740px) {
        #contact .contact-content-wrapper h1 {
          font-size: 6rem; } }
    #contact .contact-content-wrapper p {
      font-size: 3rem; }
    #contact .contact-content-wrapper form {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 40vh;
      min-height: 350px;
      justify-content: space-evenly; }
      #contact .contact-content-wrapper form input,
      #contact .contact-content-wrapper form textarea {
        padding-left: 10px;
        font-family: arial;
        width: 300px;
        min-height: 30px;
        border: none;
        outline: 1px solid green; }
      #contact .contact-content-wrapper form textarea {
        padding-top: 10px; }
      #contact .contact-content-wrapper form button {
        width: 100px;
        font-size: 1.5rem;
        height: 45px;
        background: white;
        outline: none;
        border: none;
        border-radius: 10px;
        font-weight: bold;
        color: green; }
        #contact .contact-content-wrapper form button:hover {
          color: #72b472;
          transform: scale(1.05);
          cursor: pointer; }
      #contact .contact-content-wrapper form .error {
        color: white;
        text-align: center;
        font-size: 2.5rem; }

footer {
  height: 7vh;
  min-height: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.5);
  border-top: 1px solid black;
  color: white;
  font-weight: bold; }
  @media screen and (max-width: 500px) {
    footer {
      font-size: 1.7rem; } }
  footer .phone-numbers {
    margin-bottom: 5px;
    display: flex;
    justify-content: between; }
    footer .phone-numbers .separator {
      margin: 0 10px; }
    footer .phone-numbers i {
      margin-right: 10px;
      transform: rotate(100deg); }

@media screen and (max-width: 1450px) {
  .nav-flags {
    width: 12% !important; } }

@media screen and (max-width: 1000px) {
  .nav-flags {
    width: 14% !important; } }

@media screen and (max-width: 950px) {
  .hamburger {
    cursor: pointer;
    position: absolute;
    right: 5%;
    top: 50%;
    padding: 3px;
    transform: translate(-5%, -50%);
    z-index: 2;
    transition: all 0.5s ease-in-out; }
  .lines {
    width: 30px;
    height: 3px;
    background: #8d8c8c;
    position: relative; }
    .lines::after {
      content: "";
      display: block;
      width: 30px;
      height: 3px;
      background: #8d8c8c;
      position: absolute;
      top: 8px;
      transition: all 0.5s ease-in-out; }
    .lines::before {
      content: "";
      display: block;
      width: 30px;
      height: 3px;
      background: #8d8c8c;
      position: absolute;
      top: -8px;
      transition: all 0.5s ease-in-out; }
    .lines.open {
      transform: rotate(720deg);
      background: transparent; }
      .lines.open::before {
        transform: rotate(45deg) translate(5px, 8px); }
      .lines.open::after {
        transform: rotate(-45deg) translate(3px, -8px); }
  .nav-flags img {
    position: absolute;
    top: 50%;
    padding: 3px;
    z-index: 2;
    margin-right: 5px; }
  .nav-flags #de {
    right: 13%;
    transform: translate(-13%, -50%); }
  .nav-flags #en {
    right: 17%;
    transform: translate(-17%, -50%); }
  .nav-flags #ita {
    right: 21%;
    transform: translate(-21%, -50%); }
  .nav-links {
    padding: 0 !important;
    position: fixed;
    background: white;
    height: 100vh !important;
    width: 100vw !important;
    flex-direction: column;
    clip-path: circle(100px at 95% -20%);
    -webkit-clip-path: circle(100px at 95% -20%);
    transition: all 1s ease-out;
    pointer-events: none; }
    .nav-links li {
      opacity: 0; }
      .nav-links li:nth-child(1) {
        transition: all 0.5s ease 0.1s; }
      .nav-links li:nth-child(2) {
        transition: all 0.5s ease 0.2s; }
      .nav-links li.fade {
        opacity: 1; }
  .nav-links.open {
    clip-path: circle(1700px at 90% -10%);
    -webkit-clip-path: circle(1700px at 90% -10%);
    pointer-events: all; } }

@media screen and (max-width: 600px) {
  #de {
    right: 15% !important;
    margin-right: 15px;
    transform: translate(-15%, -50%); }
  #en {
    margin-right: 15px;
    right: 20% !important;
    transform: translate(-20%, -50%); }
  #ita {
    margin-right: 15px;
    right: 25% !important;
    transform: translate(-25%, -50%); } }

@media screen and (max-width: 450px) {
  .nav-brand {
    width: 150px !important; }
  #de {
    right: 15% !important;
    transform: translate(-15%, -50%); }
  #en {
    right: 23% !important;
    transform: translate(-23%, -50%); }
  #ita {
    right: 31% !important;
    transform: translate(-31%, -50%); } }

@media screen and (max-height: 1100px) {
  .home--slider__clip {
    clip-path: ellipse(350px 600px at -10% 50%); } }

@media screen and (min-width: 1700px) {
  .home--slider__clip {
    clip-path: ellipse(350px 800px at -8% 50%); } }

@media screen and (max-width: 1200px) {
  .home {
    position: relative; }
    .home--headerContainer {
      color: black;
      margin: 0px;
      width: fit-content;
      height: fit-content;
      line-height: 35px;
      overflow: none;
      text-align: center;
      position: relative;
      background-color: #ffffffb9;
      padding: 35px;
      padding-top: 5px;
      border: 5px solid #ffffff; }
      .home--headerContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .home--header {
      width: 100% !important;
      margin: 0;
      padding: 0;
      transform: none;
      margin-top: 6.5vh;
      border: none; }
      .home--header__text {
        line-height: 40px;
        margin-bottom: 5rem !important; }
    .home--slider {
      width: 100% !important; }
      .home--slider__clip {
        display: none !important; } }

@media screen and (max-width: 420px) {
  .home--headerContainer {
    max-width: 300px;
    margin-top: 10%; } }

@keyframes homeSlider {
  6.25% {
    background-image: url(/assets/homeSlider/2.jpeg); }
  12.5% {
    background-image: url(/assets/homeSlider/3.jpeg); }
  18.75% {
    background-image: url(/assets/homeSlider/4.jpeg); }
  25% {
    background-image: url(/assets/homeSlider/5.jpeg); }
  31.25% {
    background-image: url(/assets/homeSlider/6.jpeg); }
  37.5% {
    background-image: url(/assets/homeSlider/7.jpeg); }
  43.75% {
    background-image: url(/assets/homeSlider/8.jpeg); }
  50% {
    background-image: url(/assets/homeSlider/9.jpeg); }
  56.25% {
    background-image: url(/assets/homeSlider/a.jpeg); }
  62.5% {
    background-image: url(/assets/homeSlider/b.jpeg); }
  68.75% {
    background-image: url(/assets/homeSlider/c.jpeg); }
  75% {
    background-image: url(/assets/homeSlider/d.jpeg); }
  81.25% {
    background-image: url(/assets/homeSlider/e.jpeg); }
  87.5% {
    background-image: url(/assets/homeSlider/f.jpeg); }
  93.75% {
    background-image: url(/assets/homeSlider/g.jpeg); }
  100% {
    background-image: url(/assets/homeSlider/1.jpeg); } }

.App {
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh; }

.cursor-wait {
  cursor: wait; }
